/**
 * @name: index
 * @author: lili
 * @date: 2023-06-02 10:44
 * @description：分销管理-达人管理API
 * @update: 2023-06-02 10:44
 */
import {IPageRes} from "@/apis/page";
import {get, postJ} from "@/request";
import {ICoachByPageList, ICoachByPageQuery} from "@/apis/distributionManage/coachManage/types";

/**
 * 教练管理分页查询列表
 */
export const queryCoachByPageApi = (param:ICoachByPageQuery)=> get<IPageRes<ICoachByPageList[]>>("/golf/coachManage/queryCoachByPage", param)

/**
 * 教练管理删除
 */
export const coachManageRemoveApi = (id:string)=> get(`/golf/coachManage/remove/${id}`)

/**
 * 会员下拉列表
 */
export const queryUserListApi = (userTypes:number)=> get(`/golf/user/queryUserList`,{userTypes})

/**
 * 批量新增教练
 */
export const coachManageBatchAddApi = (ids:string)=> postJ(`/golf/coachManage/batchAdd?ids=${ids}`)

/**
 * 已有教练
 */
export const existingCoachApi = (types:number)=> get(`/golf/coachManage/existingCoach`,{types})


/**
 * 启用禁用
 */
export const coachManageStatusUpdateApi = (data: ICoachByPageList) => postJ("/golf/coachManage/statusUpdate", data)
