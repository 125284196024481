
/**
 * @name: index
 * @author: Gzm
 * @date: 2023-05-31 19:34
 * @description：销售管理-赛事-赛事管理
 * @update: 2023-05-31 19:34
 */
import {Component, Vue} from "vue-property-decorator";
import {ISystemAdmin} from "@/apis/core/types";
import {
  coachManageBatchAddApi,
} from "@/apis/distributionManage/coachManage";
import {Message} from "element-ui";
import router from "@/router";
import config from "@/config";
import {contestQueryApi, contestUpAndDownShelfApi} from "@/apis/competitionManage";
import {deepCopy} from "@/utils/common";


@Component({})
export default class UserIndex extends Vue {
  // 表格加载状态
  tableLoading: boolean = false;
  queryForm: any = {
    page: 1,
    limit: 10
  }
  total: number = 0
  // 弹窗开关
  dialogVisible = false
  // titleList名称列表
  titleList = ['会员库', '教练库']
  // 会员库
  transferData = []
  // 教练库
  ids = []

  tableData: ISystemAdmin[] = []

  // 新增修改表单
  modelForm: ISystemAdmin = {
    status: 1
  }

  private readonly _label = "截至日期";
  /***
   * crudOption
   * 页面配置对象
   */
  crudOption: any = {
    searchBox: true,
    menu: false,
    column: [
      {
        label: "赛事ID",
        prop: "id",
        align: 'center',
        width: 200,
      },
      {
        label: "主图",
        prop: "contestBannerPic",
        align: 'center',
        imgPrefix: config.downloadUrl,
        type: 'image',
      },
      {
        label: "赛事名称",
        prop: "contestName",
        search: true,
        align: 'center',
      },
      {
        label: "预订金额",
        prop: "reservePrice",
        align: 'center',
      },
      {
        label: "报名人数",
        prop: "enrollNumber",
        align: 'center',
      },
      {
        label: "报名名额",
        prop: "enrollQty",
        align: 'center',
      },
      {
        label: "排序",
        prop: "sort",
        align: 'center',
      },
      {
        label: "状态",
        prop: "status",
        align: 'center',
        search: true,
        slot: true,
        value: 2,
        type: "switch",//状态:1=有效 2=无效
        dicData: [{label: '下架', value: 2}, {label: '上架', value: 1}],
      },
      {
        label: "截止日期",
        prop: "endTime",
        align: 'center',
        slot: true
      },
      {
        label: "操作",
        prop: "operate",
        align: 'center',
        slot: true
      },
      {
        "label": "截止日期",
        "prop": "endTime",
        "align": "center",
        "width": 150,
        "type": "daterange",
        "search": true,
        hide:true
      },
    ]
  }

  /**
   * 获取数据
   */
  getList() {
    this.tableLoading = true
    const query: any = deepCopy(this.queryForm)
    if (this.queryForm.endTime && this.queryForm.endTime.length === 2) {
      query.endTimeStart = this.queryForm.endTime[0]
      query.endTimeFinish = this.queryForm.endTime[1]
    } else {
      query.endTimeStart = ''
      query.endTimeFinish = ''
    }
    delete query.endTime
    contestQueryApi(query).then(e => {
      this.tableLoading = false
      this.tableData = e.list;
      this.total = e.total
    })
  }

  /**
   * 修改状态提交
   */
  handleStatus(row: any, val: number) {
    contestUpAndDownShelfApi({id: row.id, status: val}).then(e => {
      if (e) {
        this.$message({
          type: 'success',
          message: '操作成功!'
        });
        this.getList()
      }
    })
  }

  /**
   * 打开弹窗
   */
  async openAdd() {
    router.push({path:'/competitionManage/add'})
  }

  /**
   * 编辑
   * @param id
   */
  handleEdit(id: string) {
    router.push({path:'/competitionManage/add',query:{id}})
  }

  /**
   * 新增教练
   */
  handleAffirm() {
    if (this.ids.length > 0) {
      let ids = this.ids.join(',')
      coachManageBatchAddApi(ids).then(e => {
        if (e) {
          Message.success("新增成功!")
          this.dialogVisible = false
          this.getList()
        }
      })
    } else {
      Message.error("请先选择教练")
    }
  }

  created() {
    this.getList();
  }
}
