/**
 * @name: index
 * @author: Gzm
 * @date: 2023-05-30 14:39
 * @description：销售管理-旅游-旅游管理API
 * @update: 2023-05-30 14:39
 */

import {get, postJ} from "@/request";
import {IPageRes} from "@/apis/page";
import {IContestCreate, IContestQuery} from "@/apis/competitionManage/types";

/**
 * 赛事分页查询列表
 */
export const contestQueryApi = (param:IContestQuery)=> get<IPageRes<any[]>>("/golf/contest/query", param)


/**
 * 创建
 */
export const contestCreateApi = (param:IContestCreate)=> postJ(`/golf/contest/create`,param)

/**
 * 查询详情
 */
export const contestDetailApi = (id:string)=> get(`/golf/contest/detail/${id}`)

/**
 * 上下价
 */
export const contestUpAndDownShelfApi = (param:any)=> postJ(`/golf/contest/upAndDownShelf`,param)


/**
 * 修改
 */
export const contestModifyApi = (param:IContestCreate)=> postJ(`/golf/contest/modify`,param)
